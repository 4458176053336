// VideosPage.js
import Videos from '../components/Videos/Videos/Videos'

const VideosPage = () => {
    return (
        <Videos></Videos>
    )
}

export default VideosPage
